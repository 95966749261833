import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";


const Footer = () => {
  return (
    <>
      <div className="footer-section pt-17 pt-lg-21 bg-default-1 dark-mode-texts">
        <Container>
          <div className="footer-top pb-lg-14">
            <Row>
              {/* <Col xs="6" lg="2">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-7">Company</p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link
                        to="/#"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        About us
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/#"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Contact
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/#"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Careers
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/#"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Blog
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/#"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Press
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col> */}
              {/* <Col xs="6" lg="2">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-6">CPG Brands</p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link
                        to="/cpg"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Features
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/cpg#CPGPricing"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Pricing
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/#CPGContact"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Support
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col> */}

              {/* <Col xs="6" lg="2">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-7">Retailers</p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link
                        to="/#"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Features
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/#"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Pricing
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/#"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Support
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col> */}
              <Col xs="6" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-6">Legal</p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link
                        to="/terms-conditions"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/terms-conditions"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Terms &amp; Conditions
                      </Link>
                    </li>
                    {/* <li className="py-2">
                      <a
                        href="/PearCommerce_CPGBrandTermsandConditions.pdf"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        CPG Terms &amp; Conditions
                      </a>
                    </li> */}
                    <li className="py-2">
                      <a
                        href="/terms-conditions-pixel"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Pear Pixel Installer Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="7" lg="4">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-6">Resources</p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link
                        to="/pixel-install-ng"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Pear Pixel Installation
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="copyright-area border-top py-9">
            <Row className="align-items-center">
              <Col lg="6">
                <p className="copyright-text gr-text-11 mb-6 mb-lg-0 gr-text-color text-center text-lg-left">
                  © 2020 Copyright, All Right Reserved, Pear Commerce Inc.
                  {/* <i className="icon icon-heart-2-2 text-red align-middle ml-2"></i> */}
                </p>
              </Col>
              <Col lg="6" className="text-center text-lg-right">
                <ul className="social-icons list-unstyled mb-0">
                  {/* <li className="ml-7">
                    <Link to="/#" className="gr-text-color gr-hover-text-red">
                      <i className="icon icon-logo-twitter"></i>
                    </Link>
                  </li>
                  <li className="ml-7">
                    <Link to="/#" className="gr-text-color gr-hover-text-red">
                      <i className="icon icon-logo-facebook"></i>
                    </Link>
                  </li> */}
                  {/* <li className="ml-7">
                    <Link to="/#" className="gr-text-color gr-hover-text-red">
                      <i className="icon icon-instant-camera-2"></i>
                    </Link>
                  </li> */}
                  <li className="ml-7">
                    <a
                      href="https://www.linkedin.com/company/pear-commerce"
                      className="gr-text-color gr-hover-text-red"
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
