import React from "react";
import { Link } from "gatsby";

import imgL1Logo from "../../assets/image/pear-logomark-two-tone-2021.svg";
import imgL1LogoWhite from "../../assets/image/pear-logomark-white-2021.svg";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`d-block ${className}`} {...rest}>
      {white ? (
        <img height="60px" src={imgL1LogoWhite} alt="" />
      ) : (
        <img height="60px" src={imgL1Logo} alt="" />
      )}
    </Link>
  );
};

export default Logo;
