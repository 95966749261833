// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-b-2-b-js": () => import("./../../../src/pages/b2b.js" /* webpackChunkName: "component---src-pages-b-2-b-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-case-study-retailer-js": () => import("./../../../src/pages/case-study-retailer.js" /* webpackChunkName: "component---src-pages-case-study-retailer-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-co-working-js": () => import("./../../../src/pages/co-working.js" /* webpackChunkName: "component---src-pages-co-working-js" */),
  "component---src-pages-consultation-js": () => import("./../../../src/pages/consultation.js" /* webpackChunkName: "component---src-pages-consultation-js" */),
  "component---src-pages-contact-1-js": () => import("./../../../src/pages/contact1.js" /* webpackChunkName: "component---src-pages-contact-1-js" */),
  "component---src-pages-contact-2-js": () => import("./../../../src/pages/contact2.js" /* webpackChunkName: "component---src-pages-contact-2-js" */),
  "component---src-pages-contact-3-js": () => import("./../../../src/pages/contact3.js" /* webpackChunkName: "component---src-pages-contact-3-js" */),
  "component---src-pages-cpg-js": () => import("./../../../src/pages/cpg.js" /* webpackChunkName: "component---src-pages-cpg-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-ecommerce-trends-2022-js": () => import("./../../../src/pages/ecommerce-trends-2022.js" /* webpackChunkName: "component---src-pages-ecommerce-trends-2022-js" */),
  "component---src-pages-home-cpg-js": () => import("./../../../src/pages/home-CPG.js" /* webpackChunkName: "component---src-pages-home-cpg-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-details-js": () => import("./../../../src/pages/job-details.js" /* webpackChunkName: "component---src-pages-job-details-js" */),
  "component---src-pages-job-directory-js": () => import("./../../../src/pages/job-directory.js" /* webpackChunkName: "component---src-pages-job-directory-js" */),
  "component---src-pages-job-openings-js": () => import("./../../../src/pages/job-openings.js" /* webpackChunkName: "component---src-pages-job-openings-js" */),
  "component---src-pages-mobile-app-js": () => import("./../../../src/pages/mobile-app.js" /* webpackChunkName: "component---src-pages-mobile-app-js" */),
  "component---src-pages-pixel-install-js": () => import("./../../../src/pages/pixel-install.js" /* webpackChunkName: "component---src-pages-pixel-install-js" */),
  "component---src-pages-pixel-install-ng-js": () => import("./../../../src/pages/pixel-install-ng.js" /* webpackChunkName: "component---src-pages-pixel-install-ng-js" */),
  "component---src-pages-pricing-1-js": () => import("./../../../src/pages/pricing1.js" /* webpackChunkName: "component---src-pages-pricing-1-js" */),
  "component---src-pages-pricing-2-js": () => import("./../../../src/pages/pricing2.js" /* webpackChunkName: "component---src-pages-pricing-2-js" */),
  "component---src-pages-pricing-3-js": () => import("./../../../src/pages/pricing3.js" /* webpackChunkName: "component---src-pages-pricing-3-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/product-details.js" /* webpackChunkName: "component---src-pages-product-details-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-reset-pass-js": () => import("./../../../src/pages/reset-pass.js" /* webpackChunkName: "component---src-pages-reset-pass-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-saas-subscription-js": () => import("./../../../src/pages/saas-subscription.js" /* webpackChunkName: "component---src-pages-saas-subscription-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-telemedicine-js": () => import("./../../../src/pages/telemedicine.js" /* webpackChunkName: "component---src-pages-telemedicine-js" */),
  "component---src-pages-templates-js": () => import("./../../../src/pages/templates.js" /* webpackChunkName: "component---src-pages-templates-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-terms-conditions-pixel-agreement-js": () => import("./../../../src/pages/terms-conditions-pixel-agreement.js" /* webpackChunkName: "component---src-pages-terms-conditions-pixel-agreement-js" */),
  "component---src-pages-terms-conditions-pixel-js": () => import("./../../../src/pages/terms-conditions-pixel.js" /* webpackChunkName: "component---src-pages-terms-conditions-pixel-js" */),
  "component---src-pages-video-conference-js": () => import("./../../../src/pages/video-conference.js" /* webpackChunkName: "component---src-pages-video-conference-js" */),
  "component---src-pages-web-application-js": () => import("./../../../src/pages/web-application.js" /* webpackChunkName: "component---src-pages-web-application-js" */),
  "component---src-pages-whitepaper-future-proof-ecomm-site-js": () => import("./../../../src/pages/whitepaper-future-proof-ecomm-site.js" /* webpackChunkName: "component---src-pages-whitepaper-future-proof-ecomm-site-js" */),
  "component---src-pages-whitepaper-optimize-acquisition-channels-js": () => import("./../../../src/pages/whitepaper-optimize-acquisition-channels.js" /* webpackChunkName: "component---src-pages-whitepaper-optimize-acquisition-channels-js" */),
  "component---src-pages-whitepaper-retail-media-network-sales-js": () => import("./../../../src/pages/whitepaper-retail-media-network-sales.js" /* webpackChunkName: "component---src-pages-whitepaper-retail-media-network-sales-js" */),
  "component---src-pages-whitepaper-suppliers-sending-to-competition-js": () => import("./../../../src/pages/whitepaper-suppliers-sending-to-competition.js" /* webpackChunkName: "component---src-pages-whitepaper-suppliers-sending-to-competition-js" */)
}

