import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef
} from "react";

import styled, { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import AOS from "aos";

import Header from "../Header";
import Footer from "../Footer";

import ModalVideo from "../ModalVideo";

import GlobalContext from "../../context/GlobalContext";

import GlobalStyle from "../../utils/globalStyle";

import imgFaviconDev from "../../assets/image/pear-favicon-white-on-red-2021-fatter.png";

import imgFavicon from "../../assets/image/pear-favicon-white-on-green-2021-fatter.png";
// import imgFavicon180 from "../../assets/cropped-pearfavicon-180x180.png";
// import imgFavicon192 from "../../assets/cropped-pearfavicon-192x192.png";
// import imgFavicon270 from "../../assets/cropped-pearfavicon-270x270.png";
// import imgFavicon32 from "../../assets/cropped-pearfavicon-32x32.png";

import "../../assets/fonts/fontawesome-5/webfonts/fa-brands-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-regular-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-solid-900.ttf";

import "../../assets/fonts/typography-font/CircularStd-Bold.ttf";
import "../../assets/fonts/typography-font/CircularStd-BoldItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Book.ttf";
import "../../assets/fonts/typography-font/CircularStd-BookItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Medium.ttf";

import "../../assets/fonts/icon-font/fonts/avasta.ttf";
import "../../assets/fonts/icon-font/css/style.css";

import "../../../node_modules/slick-carousel/slick/slick.css";
// import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../node_modules/aos/dist/aos.css";

import "../../assets/fonts/icon-font/css/style.css";
import "../../assets/fonts/typography-font/typo.css";
import "../../assets/fonts/fontawesome-5/css/all.css";

import "../../scss/bootstrap.scss";
import "../../scss/main.scss";

import { get, merge } from "lodash";

// the full theme object
import { theme as baseTheme } from "../../utils";

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;

// options for different color modes
const modes = { light: "light", dark: "dark" };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = mode =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors)
  });

const Layout = ({ children, pageContext }) => {
  const gContext = useContext(GlobalContext);

  const [visibleLoader, setVisibleLoader] = useState(true);

  useLayoutEffect(() => {
    AOS.init({ once: true });
    setVisibleLoader(false);
  }, []);

  // Navbar style based on scroll
  const eleRef = useRef();

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
    window.addEventListener(
      "pushState",
      function (event) {
        // The pushstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
  }, [gContext]);

  if (process.env.DEV) {
    console.log("DEV: " + process.env.DEV);
    console.log(process.env.DEV ? "IS DEV" : "IS NOT DEV");
  }

  const favicon = process.env.DEV ? imgFaviconDev : imgFavicon;

  const heapId = process.env.DEV ? "1902841081" : "2219762047";


  const head = (
    <Helmet>
      <title>Pear Commerce</title>
      <link rel="icon" type="image/png" href={favicon} />
      <meta name="msapplication-TileImage" content={favicon}></meta>
      <meta name="fo-verify" content="00eb9834-916f-4a1a-8d75-b3e0fb6145bb"></meta>
      <script type="text/javascript">{`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-MF8X2ZL');
            `}
      </script>
      <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>
      <script type="text/javascript">
        {`(function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('bElvO73yY2VaZMqj');`}
      </script>

      <link rel='stylesheet' type='text/css' href='https://p.visitorqueue.com/styles/6fef2889-64d4-4622-860f-d491f223dfdc.css' id='vq-flick-styles' />
      <script>
        {`function vqTrackPc(){return 1;}`}
      </script>
      <link rel='dns-prefetch' href='//t.visitorqueue.com' style={{ display: 'none !important' }} />
      <link rel='preload' href='//t.visitorqueue.com/p/tracking.min.js?id=6fef2889-64d4-4622-860f-d491f223dfdc' as='script' style={{ display: 'none !important' }} />
      <script>
        {`function vqTrackId(){return '6fef2889-64d4-4622-860f-d491f223dfdc';} (function(d, e) { var el = d.createElement(e); el.sa = function(an, av){this.setAttribute(an, av); return this;}; el.sa('id', 'vq_tracking').sa('src', '//t.visitorqueue.com/p/tracking.min.js?id='+vqTrackId()).sa('async', 1).sa('data-id', vqTrackId()); d.getElementsByTagName(e)[0].parentNode.appendChild(el); })(document, 'script');`}
      </script>
      <script async src="https://tag.clearbitscripts.com/v1/pk_b5361f604d88a27f3f0bb84ff4a250f5/tags.js"></script>
    </Helmet>
  );


  const site = pageContext.layout != "bare" ?
    (
      <div className="site-wrapper overflow-hidden" ref={eleRef}>
        <Header isDark={gContext.headerDark} />
        {children}
        <Footer isDark={gContext.footerDark} />
      </div>
    ) :
    (
      <div className="site-wrapper overflow-hidden" ref={eleRef}>
        {children}
        <Footer isDark={gContext.footerDark} />
      </div>
    );

  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />

          {head}

          <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
            <div className="load-circle">
              <span className="one"></span>
            </div>
          </Loader>

          {site}

          <ModalVideo />
        </div>
      </ThemeProvider>
    </>
  );
};

export default Layout;
